import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import styled from '@emotion/styled';
import Config from '../Config';

const Styled = styled.div`
color: rgba(0, 0, 0, 0.6);
    position: relative;
    width: 100%;
    bottom: 1px;
  
`;

function Copyright(props) {
    return (
      <Styled>
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href={Config.Copyright_url}>
          {Config.Copyright_name}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      </Styled>

    );
  }
  export default Copyright;