import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import CmdSer from "../Service/CmdSer";
import ComandHistorique from "./ComandHistorique";
import { CartDispatchContext, CartStateContext,loginUser } from "../context/Main";
import Loding from "./Loding";
const Styled = styled.div`

`;

const Historique = () => {
  const { user } = useContext(CartStateContext);
  const [offset,setoffset] = useState(0);
  const [loding,setloding] = useState(false);
  const [state,setstate] = useState("-99");
  const [DataH,setDataH] = useState([]);
  const getdata  = async() =>{
    setloding(true);
    setTimeout(async () => {
    const re = await CmdSer.getHistorique(user.id,state,10,offset);
    if(DataH.length!= 0){
      let old = DataH;
      re.data.forEach(element => {
        old.push(element);
      });
      setDataH(old);
    }else{
      setDataH(re.data);
    }
    setloding(false);

  },1000);
}

  useEffect(()=>{
    getdata();
  },[offset,state]);

  return (
    <div className="allHistorique">
      {
        DataH.length?DataH.map((item,x)=><ComandHistorique key={x} data={item}/>):<div className="notfound"></div>
       
      }
      {
         DataH.length?<a className="loder" onClick={(e)=>setoffset(parseInt(offset)+10)}>{loding?<Loding color={"#fff"} type={"balls"} height={"20px"} width={"20px"} />:"load more"}</a>:""
         
      }
        
    </div>
  );
};

export default Historique;
