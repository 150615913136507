import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  CartDispatchContext,
  CartStateContext,
  loginUser,
} from "../context/Main";
import { BsFillEyeFill,BsFillEyeSlashFill } from "react-icons/bs";
import CmdSer from "../Service/CmdSer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import Button from "@mui/material/Button";
import Cards from 'react-credit-cards';
import { useNavigate } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const Styled = styled.div`
  margin-top: 100px;
  .pass_chang h3 {
    text-align: center;
    text-transform: uppercase;
    margin: 15px 0;
}
  .hero {
    background-color: #212121;
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 18px;
  }
  button#fgfgfg {
    height: 45px;
    width: 230px;
    float: right;
    margin-top: 15px;
}
`;

export const MyAccount = () => {
  const navigate = useNavigate();
  const [viewpassword, setviewpassword] = useState(false);
  const dispatch = useContext(CartDispatchContext);
  const {islogin, user } = useContext(CartStateContext);
  const [pass1, setpass1] = useState("");
  const [pass2, setpass2] = useState("");
  const [err, Seterr] = useState("");
  const [loading,setloading] = useState(false);
  const [istrue, setistrue] = useState(false);
  const[codeContry,SetcodeContry] = useState("dz");
  const [dataUser, SetdataUser] = useState({
    first_name: user.first_name,
    id: user.id,
    last_name: user.last_name,
    email: user.email,
    pays: user.pays,
    email_stripe: user.email_stripe,
    card_cvc: user.card_cvc,
    card_expiry: user.card_expiry,
    card_number: user.card_number,
    phone_number: user.phone_number,
    addressFav: user.addressFav
  });

  const Changepass = (e, type) => {
    Seterr('');
    if (type == 1) {
      setpass1(e.target.value);
      SetdataUser({...dataUser,pass:e.target.value});
    }
    if (type == 2) {
      setpass2(e.target.value);
      SetdataUser({...dataUser,pass:e.target.value});
    }
  }

  // update data
  const updateData = async () => {
    setloading(true);
    setTimeout(async () => {
    if ((pass1 == "" && pass2 == "") || (pass1 == pass2 && pass2 != "" && pass1 != "")) {
            const res = await CmdSer.updateDataUser(dataUser);
            if(res.data.state){
                const res = await CmdSer.userdata(user.id);
                loginUser(dispatch,res.data);
                toast.success('Modifié avec succès', {
                  position: toast.POSITION.BOTTOM_LEFT,
                });

              
            }
    }else{
        if (pass1 != pass2) {
            toast.error('le mot de passe ne correspond pas', {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
    }
    setloading(false);
},1000);
  };

  useEffect(() => {
    if (!islogin) navigate("/Login");
  }, []);

  return (
    <Styled>
      <Container>
        <div className="hero">
        <h3>Votre profil</h3>
           <p>Mettre à jour vos informations personnelles</p>
        </div>

        <div className="form_data">
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <TextField
                autoComplete="given-name"
                variant="standard"
                fullWidth
                label="Prénom"
                value={dataUser.first_name}
                onChange={(e)=>SetdataUser({...dataUser,first_name:e.target.value})}
                autoFocus
              />
            </Grid>

            <Grid item xs={6} md={6}>
            <TextField
                autoComplete="given-name"
                variant="standard"
                type="text"
                fullWidth
                label="Nom"
                value={dataUser.last_name}
                onChange={(e)=>SetdataUser({...dataUser,last_name:e.target.value})}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={12}>
            <TextField
                autoComplete="given-name"
                variant="standard"
                fullWidth
                label="Email"
                type="email"
                value={dataUser.email}
                onChange={(e)=>SetdataUser({...dataUser,email:e.target.value})}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={12}>
            <MuiPhoneNumber
                  defaultCountry={codeContry}
                  variant="standard"
                  fullWidth
                  value={dataUser.phone_number}
                  onChange={(value)=>SetdataUser({...dataUser,phone_number:value})}
                  label="Phone"
                  regions={["europe", "africa"]}
                />
            </Grid>
            <Grid item xs={12} md={12} className="pass_chang" >
              <h3>changer le mot de passe</h3>
              

            </Grid>
            <Grid item xs={12} md={12}>
            <TextField
                autoComplete="given-name"
                variant="standard"
                name="email"
                fullWidth
                label="Entrez à nouveau le mot de pass"
                type={!viewpassword?"password":"text"} 
                value={pass1} onChange={(e)=>Changepass(e, 1)}

                autoFocus
              />
              </Grid>
              <Grid item xs={12} md={12}>
            <TextField
                autoComplete="given-name"
                variant="standard"
                fullWidth
                label="Re-saisir le mot de passee"
                type={!viewpassword?"password":"text"} 
                value={pass2} onChange={(e)=>Changepass(e, 2)}
                InputProps={{
                  endAdornment: <InputAdornment position="end" onClick={(e)=>setviewpassword(!viewpassword)} >
                    {!viewpassword?<BsFillEyeFill/>:<BsFillEyeSlashFill/>}
                  </InputAdornment>,
                }}
                autoFocus
              />
              </Grid>
             <Grid item xs={12} md={12}>
             <LoadingButton
             loading={loading}
             onClick={updateData}
              type="submit"
              id="fgfgfg"
              variant="contained"
              >Enregistrer
            </LoadingButton>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Styled>
  );
};
