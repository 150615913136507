import React, { useContext, useEffect, useState } from 'react'
import { Form,InputGroup,Button } from 'react-bootstrap'
import styled from 'styled-components'
import CmdSer from '../Service/CmdSer';
import Loding from './Loding';
import { CartStateContext,CartDispatchContext,loginUser} from '../context/Main';
import { BsFillEyeFill,BsFillEyeSlashFill } from "react-icons/bs";
import { toast } from 'react-toastify';
const Styled = styled.div`
button.btn.btn-primary div {
    margin: auto;
}
p.err_form {
    font-size: 12px;
    color: red;
    text-align: center;
}
`;

export const Login = (props) => {
const { islogin } = useContext(CartStateContext);
const dispatch = useContext(CartDispatchContext);

const [viewpassword,setviewpassword] = useState(false);
const [email,setemail] = useState("");
const [pass,Setpass] = useState("");
const [loding,setloding] = useState(false);
const [err,seterr] =  useState("");
const loginApi = async () => {
    if(email!="" && pass!=""){
        setloding(true);
        setTimeout(async () => {
            const data =  await CmdSer.login({
                email:email,pass:pass
            });
            if(data.data.id){
                const re = await CmdSer.userdata(data.data.id);
                loginUser(dispatch,re.data);
                props.handleClose();
            }else{
              toast.error("Email or Password is incorrect", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
           setloding(false);
          }, 1000)
    }else{
        seterr('');
    }
}
useEffect(()=>{
if(islogin){
props.handleClose();
}
},[]);

  return (
      <Styled>
<Form className='form_login'>
  <InputGroup className="mb-3">
    <Form.Label>Email address</Form.Label>
    <Form.Control type="email" value={email} onChange={(e)=>setemail(e.target.value)} placeholder="Enter email" />
  </InputGroup>
  <InputGroup className="mb-3">
    <Form.Label>Password</Form.Label>
    <Form.Control type={!viewpassword?"password":"text"}  value={pass} onChange={(e)=>Setpass(e.target.value)}  placeholder="Password" />
    <InputGroup.Text onClick={(e)=>setviewpassword(!viewpassword)}>{viewpassword?<BsFillEyeFill/>:<BsFillEyeSlashFill/>}
    </InputGroup.Text>
  </InputGroup>
  {
    err!=""?<p className='err_form'>{err}</p>:""
  }
  <Button variant="primary" onClick={(e)=>loginApi()} >
    {loding?<Loding color={"#fff"} type={"balls"} height={"40px"} width={"50px"} />:"Login"}
  </Button>
</Form>
</Styled>)

}
