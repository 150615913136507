import firebase from 'firebase/app'
import 'firebase/auth'
var firebaseConfig = {
  apiKey: "AIzaSyAMH9Y1OuXuCguJheZxaIYEsy1IVtr9zbw",
  authDomain: "zencab-32493.firebaseapp.com",
  projectId: "zencab-32493",
  storageBucket: "zencab-32493.appspot.com",
  messagingSenderId: "540334708489",
  appId: "1:540334708489:web:fdb67b4bd43fc9490665d8",
  measurementId: "G-2RWF38192J"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
export default firebase