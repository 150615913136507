import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row, Button, InputGroup } from "react-bootstrap";
import { CartDispatchContext, CartStateContext,loginUser } from "../context/Main";
import { BsFillEyeFill,BsFillEyeSlashFill } from "react-icons/bs";
import styled from 'styled-components';
import CmdSer from "../Service/CmdSer";
import Loding from "./Loding";


const Styled = styled.div`
span.err_section {
    color: red;
    font-size: 12px;
    float: right;
    margin: 10px 0;
}
button.btn.btn-primary div {
    margin: auto;
}
`;

const MyAccount = () => {

    const [viewpassword,setviewpassword] = useState(false);
    const dispatch = useContext(CartDispatchContext);
    const { islogin,user } = useContext(CartStateContext);
    const [pass1, setpass1] = useState("");
    const [pass2, setpass2] = useState("");
    const [err,Seterr] = useState("");
    const [loding,setloding] = useState(false);
    const [istrue,setistrue] = useState(false);

    const [dataUser,SetdataUser] = useState({
        first_name: user.first_name, 
        id:user.id, 
        last_name: user.last_name,
        email: user.email,
        pays: user.pays,
        email_stripe: user.email_stripe,
        card_cvc: user.card_cvc,
        card_expiry: user.card_expiry,
        card_number: user.card_number,
        phone_number: user.phone_number,
    });

    
  const Changepass = (e, type) => {
    Seterr('');
    if (type == 1) {
      setpass1(e.target.value);
    }
    if (type == 2) {
      setpass2(e.target.value);
    }
  }
    
    // update data 
    const updateData = async() =>{
        setloding(true);
        setTimeout(async () => {
            
        if ((pass1 == "" && pass2 == "") || (pass1 == pass2 && pass2 != "" && pass1 != "")) {
            // sand data 
                const res = await CmdSer.updateDataUser(dataUser);
                if(res.data.state){
                    setistrue(true);
                    const res = await CmdSer.userdata(user.id);
                    loginUser(dispatch,res.data);
                }
        }else{
            if (pass1 != pass2) {
                Seterr('le mot de passe ne correspond pas');
              }
        }
        setloding(false);
    },1000);

    }




    useEffect(()=>{

    },[]);


  return (
    <>
      <Form className="form_login">
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" value={dataUser.first_name} onChange={(e)=>SetdataUser({...dataUser,first_name:e.target.value})}  placeholder="Enter First Name" />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" value={dataUser.last_name} onChange={(e)=>SetdataUser({...dataUser,last_name:e.target.value})} placeholder="Enter Last Name" />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className="mb-3">
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" value={dataUser.email} onChange={(e)=>SetdataUser({...dataUser,email:e.target.value})} placeholder="Enter email" />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Phone</Form.Label>
          <Form.Control type="text" value={dataUser.phone_number} onChange={(e)=>SetdataUser({...dataUser,phone_number:e.target.value})} placeholder="Enter Phone" />
        </Form.Group>
        <InputGroup className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control type={!viewpassword?"password":"text"}  value={pass1} onChange={(e)=>Changepass(e, 1)}  placeholder="Password" />
          <InputGroup.Text onClick={(e)=>setviewpassword(!viewpassword)}>{viewpassword?<BsFillEyeFill/>:<BsFillEyeSlashFill/>}</InputGroup.Text>
        </InputGroup>
        <Form.Group className="mb-3">
          <Form.Label>Retry Password</Form.Label>
          <Form.Control type={!viewpassword?"password":"text"}  value={pass2} onChange={(e) => Changepass(e, 2)} placeholder="Password" />
        </Form.Group>
        <Col lg={12} xs={12}>
          <span className='err_section'>{err}</span>
         </Col>

        <Button variant="primary" onClick={(e) =>updateData()}>
          {loding?<Loding color={"#fff"} type={"balls"} height={"40px"} width={"50px"} />:"Save"}
        </Button>

      </Form>
    </>
  );
};

export default MyAccount;
