import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import MUIDataTable from "mui-datatables";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Box, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "react-credit-cards/es/styles-compiled.css";
import Cards from "react-credit-cards";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Moment from "moment";
import Button from "@mui/material/Button";
import { CartStateContext } from "../context/Main";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import CmdSer from "../Service/CmdSer";
import creditCardType from 'credit-card-type';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';


Moment.locale("en");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 90 + "%",

  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Styled = styled.div`
  .ghModal {
    max-width: 700px;
  }

  .df {
    position: fixed;
    bottom: 5%;
    right: 5%;
  }
  margin-top: 100px;
  .hero {
    background-color: #212121;
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
  }
`;

export const CardList = () => {
    const columns = [
        {
            name: "full_name",
            label: "Type carte",
            options: {
              filter: true,
               customBodyRenderLite: (dataIndex) => {
                  return <>{creditCardType(data[dataIndex].card_number)[0].type}</>;
              }
            },
          },
          {
            name: "full_name",
            label: "Name",
            options: {
              filter: true,
              sort: true,
            },
          },
        
        {
          name: "card_number",
          label: "Numéro de carte",
          options: {
            filter: true,
            customBodyRenderLite: (dataIndex) => {
                return <>{data[dataIndex].card_number.slice(0,4)+"************"}</>;
            }
          },
        },
        {
          name: "expiry_date",
          label: "Date d'expiration",
          options: {
            filter: true,
            sort: false,
          },
        },
        {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              customBodyRenderLite: (dataIndex) => {
                return <>
                <div className="fex">
                <Switch
                checked={data[dataIndex].state=="1"?true:false}
                onChange={(e)=>updateData({id:data[dataIndex].id,state: data[dataIndex].state==1?'0':'1'})}
                inputProps={{ 'aria-label': 'controlled' }}
                />
                <IconButton  onClick={(e)=>setcard({...card,
                    id:data[dataIndex].id,
                    cvc: data[dataIndex].card_cvc,
                    expiry: data[dataIndex].expiry_date,
                    focus: "",
                    name: data[dataIndex].full_name,
                    number: data[dataIndex].card_number,
                    })&setupdate(true)&setOpen(true)} aria-label="Example">
                    <EditIcon color="success"/>
                </IconButton>

                </div>
                </>;
            }
            },
          }

      ];
      const options = {
        filterType: "checkbox",
    };
  const { islogin,user } = useContext(CartStateContext);
  const [data,setdata] =  React.useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [loading,setloading] = useState(false);
  const handleOpen = () => setcard({
    cvc: "",
    id:"",
    expiry: "",
    focus: "",
    name: "",
    state: 1,
    number: "",

  })&setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState();
  const [card, setcard] = useState({
    cvc: "",
    id:"",
    expiry: "",
    focus: "",
    name: "",
    state: 1,
    number: "",
  });

  const [update, setupdate] = useState(false);

  const updateData = (data) => {
    setupdate(false);
    setloading(true);
    setTimeout(async () => {
    const res = await CmdSer.update_favorite_card(data);
    setloading(false);
    toast.success('Mise à jour réussie', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    },1000);

  }

  const addData = () => {
    setloading(true);
    setTimeout(async () => {
        if(card.cvc!=""&&card.expiry!=""&&card.name!=""&&card.number!=""){
            const res = await CmdSer.add_favorite_card({
                id_client:user.id,
                full_name:card.name,
                card_number:card.number,
                card_cvc:card.cvc,
                expiry_date:card.expiry
            });

            if(res.data.state=="ok"){
                setOpen(false);
                toast.success('Ajouté avec succès', {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
                
            }
        }else{
            toast.error('Veuillez entrer toutes les données', {
                position: toast.POSITION.BOTTOM_LEFT,
              });
        }
    setloading(false);
    },1000);


  }

const getDate = async () => {
    const re = await CmdSer.get_favorite_card({id_client:user.id});
    setdata(re.data);

}

useEffect(() => {
    if (!islogin) navigate("/Login");
     getDate();
}, [loading]);

  return (
    <Styled>
      <Container>
        <div className="hero">
          <h3>Vos cartes bancaires</h3>
          <p>Visualisez, modifiez et ajoutez de nouvelles cartes bancaires</p>
        </div>

        <MUIDataTable data={data} columns={columns} options={options} />
        <Fab
          onClick={handleOpen}
          color="primary"
          className="df"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          id="sdsds111"
          aria-describedby="modal-modal-description"
        >
          <Box className="ghModal" sx={style}>
            <Cards
              cvc={card.cvc}
              expiry={card.expiry}
              focused={card.focus}
              name={card.name}
              number={card.number}
            />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  variant="standard"
                  size="small"
                  name="name"
                  type={"text"}
                  fullWidth
                  id="name"
                  label="Nom complet"
                  value={card.name}
                  onChange={(e) => setcard({ ...card, name: e.target.value })}
                  onFocus={(e) => setcard({ ...card, focus: e.target.name })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete="given-name"
                  variant="standard"
                  size="small"
                  name="number"
                  type={"number"}
                  maxRows={15}
                  fullWidth
                  id="firstName"
                  label="Numéro de carte"
                  value={card.number}
                  onChange={(e) => setcard({ ...card, number: e.target.value })}
                  onFocus={(e) => setcard({ ...card, focus: e.target.name })}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  autoComplete="given-name"
                  variant="standard"
                  size="small"
                  name="cvc"
                  type={"number"}
                  fullWidth
                  id="cvc"
                  label="cvc"
                  value={card.cvc}
                  onChange={(e) => setcard({ ...card, cvc: e.target.value })}
                  onFocus={(e) => setcard({ ...card, focus: e.target.name })}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DatePicker
                  views={["year", "month"]}
                  label="Date d'expiration"
                  name="expiry"
                  onChange={(newValue) => {
                    setcard({
                      ...card,
                      expiry: Moment(newValue).format("MM/YY"),
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      onFocus={(e) => setcard({ ...card, focus: "expiry" })}
                      value={card.expiry}
                      name="expiry"
                      fullWidth
                      variant="standard"
                      size="small"
                      {...params}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}></Grid>{" "}
              <Grid item xs={6}>
                <LoadingButton 
                loading={loading}
                onClick={(e)=>update?updateData({
                    id_client:user.id,
                    id:card.id,
                    full_name:card.name,
                    card_number:card.number,
                    card_cvc:card.cvc,
                    expiry_date:card.expiry
                }):addData()} 
                variant="contained">
                    {update?"Mettre à jour":"Ajouter"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Container>
    </Styled>
  );
};
