import React, { useState } from 'react'
import ReactTimeAgo from "react-time-ago";
import { AiFillCar,AiOutlineExclamationCircle,AiOutlineFieldTime,AiOutlineIdcard } from "react-icons/ai";
import { VscCircleFilled,VscCircleOutline } from "react-icons/vsc";
import { AiOutlineUser } from "react-icons/ai";

const ComandHistorique = (props) => {
  
  const [view,setview] = useState(true);
    const stateeta = (state) => {
        if(state == 0) return <><div className=" state red">Client annule</div></>;
        if(state == 1)  return  <><div className="state blue">recherche de chauffeur</div></>;
        if(state == 2)  return  <><div className="state blue">chauffeur accepté</div></>;
        if(state == 3)  return  <><div className="state blue">En route</div></>;
        if(state == -6)  return  <><div className="state red">chauffeur annulé </div></>;
        if(state == 4)  return  <><div className="state ver">Terminé</div></>;
        if(state == -1)  return  <><div className="state red">chauffeur refusé</div></>;
        if(state == -2)  return  <><div className="state red">Client annulé</div></>;
        if(state == -3)  return  <><div className="state red">Client annulé </div></>;
        if(state == -4)  return  <><div className="state red">Aucune chauffeur trouvée </div></>;
        if(state == 10 )  return  <><div className="state pink">réservation future</div></>;
    }


    const dd = (cart) => {
      if(cart == 4) return <>Cart bancaire</>;
      if(cart == 3)  return  <>wallet</>;
      if(cart == 2 || cart == 1)  return  <>Cash</>;
    }

  return (
    <>
         <div className="option">
          <div className="first">
              {stateeta(props.data.statut)}
              <p className="time"><ReactTimeAgo date={props.data.date}  /></p>
          </div>
          <div className={"sacande"+(view?" hid":"")}>
              <div className="care">
              <AiFillCar/><p>{props.data.car_name}</p>
              </div>
              <div className="id_more_info">
              <p>N°:{props.data.id}</p>
                <a onClick={(e)=>setview(!view)}>
                <AiOutlineExclamationCircle/>
                </a>
              </div>

          </div>
          <div className={"more_data" +(view?" hid":"")}>
            <div className="s1">
              <div className="icon"> <AiOutlineFieldTime/><span>{props.data.time}min</span></div>
              <div className="icon"> <AiOutlineIdcard/><span>{
                dd(props.data.payment_method)
                }</span></div>
              <div className="icon"> <AiFillCar/><span>{props.data.car_name}</span></div>
            </div>
            {props.data.chauffeur_first_name?<div className="s4">
            <div className="user_info"><AiOutlineUser/><p>{props.data.chauffeur_first_name+" "+props.data.chauffeur_last_name}</p></div>
             <div><p className='a'>{props.data.date}</p></div>

            </div>:""}
            <div className="s3">
              <div className="adr1">
                <VscCircleOutline/><p>{props.data.location_start.split(',')[0]}<br/>{props.data.location_start.split(',')[1]} </p>
              </div>
              <span></span>
              <div className="adr2">
              <VscCircleFilled/><p>{props.data.location_end.split(',')[0]}<br/>{props.data.location_end.split(',')[1]} </p>
              </div>
            </div>
            <div className="s2">
              <div className="price">Montant : <span>{props.data.prix_total} DA</span></div>
              <div className="price">Encaisser : <span>{props.data.prix_total} DA</span></div>
            </div>

            
          </div>

      </div>
    </>
  )
}

export default ComandHistorique