import React, { useReducer, createContext, useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const initialState = {
  islogin:false,
  courency:"€",
  user:{}
};

export const CartStateContext = createContext();
export const CartDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_LOGIN":
        return {
          ...state,
          user: action.payload.user
    };


    case "TOGGLE_LOGIN_STATE":
        return {
          ...state,
          islogin: true
    };
    

    case "TOGGLE_LOGOUT":
      return {
        ...state,
        user:{},
        islogin: false
    };

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const loginUser=(dispatch,user) =>{
  return dispatch({
    type: "TOGGLE_LOGIN",
    payload: {
      user: user
    }
  }),dispatch({type: "TOGGLE_LOGIN_STATE"});
}

export const logout=(dispatch) =>{
  return dispatch({
    type: "TOGGLE_LOGOUT"
  });
}


const Main = ({ children }) => {
  const [courency, setcourency] = useLocalStorage("courency","€");
  const [userdata, setuserdata] = useLocalStorage("user",{});
  const [islogin, setislogin] = useLocalStorage("islogin",false);
  const persistedCartState = {
    islogin:islogin,
    user: userdata,
    courency:courency,
  };

  const [state, dispatch] = useReducer(reducer, persistedCartState);
  useEffect(() => {
    setuserdata(state.user);
    setislogin(state.islogin);
    setcourency(state.courency);

  }, [state.user,state.islogin,]);
  return (
    <CartDispatchContext.Provider value={dispatch}>
      <CartStateContext.Provider value={state}>
        {children}
      </CartStateContext.Provider>
    </CartDispatchContext.Provider>
  );
};

export default Main;