import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import Container from "@mui/material/Container";
import MUIDataTable from "mui-datatables";
import CmdSer from '../Service/CmdSer';
import Config from '../Config';
import { CartStateContext } from '../context/Main';
import { useNavigate } from 'react-router-dom';
import { FormControl, Grid, IconButton, InputLabel, MenuItem} from '@mui/material';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

const stateeta = (state) => {
  if(state == 0) return <><div className=" state red">Client annule</div></>;
  if(state == 1)  return  <><div className="state blue">recherche de chauffeur</div></>;
  if(state == 2)  return  <><div className="state blue">chauffeur accepté</div></>;
  if(state == 3)  return  <><div className="state blue">En route</div></>;
  if(state == -6)  return  <><div className="state red">chauffeur annulé </div></>;
  if(state == 4)  return  <><div className="state ver">Terminé</div></>;
  if(state == -1)  return  <><div className="state red">chauffeur refusé</div></>;
  if(state == -2)  return  <><div className="state red">Client annulé</div></>;
  if(state == -3)  return  <><div className="state red">Client annulé </div></>;
  if(state == -4)  return  <><div className="state red">Aucune chauffeur trouvée </div></>;
  if(state == 10 )  return  <><div className="state pink">réservation future</div></>;
}
const Styled = styled.div`
  margin-top: 100px;
  padding-bottom: 2%;
  .hero {
    background-color: #212121;
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .cartfillter {
    padding: 30px 0;
}
.fex {
    display: flex;
}
`;
export const BookingList = () => {
  const { islogin ,user} = useContext(CartStateContext);
  const navigate = useNavigate();
  const [data,setdata] = useState([]);
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "statut",
      label: "statut",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            stateeta(value)
        
          );
        }
      }
    },


    
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "chauffeur_first_name",
      label: "Passager",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          if(data[dataIndex].payer_first_name!=null && data[dataIndex].payer_last_name!=null){
            let val = data[dataIndex].payer_first_name + " " + data[dataIndex].payer_last_name;
            return val;
          }else{
            return "";
          }
     
        }
      },
    },
    {
      name: "chauffeur_first_name",
      label: "Chauffeur ",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          if(data[dataIndex].chauffeur_first_name!=null && data[dataIndex].chauffeur_last_name!=null){
            let val = data[dataIndex].chauffeur_first_name + " " + data[dataIndex].chauffeur_last_name;
            return val;
          }else{
            return "";
          }
     
        }
      },
    },
    {
      name: "location_start",
      label: "Adresse de départ",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "location_end",
      label: "Adresse de destination",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "price_final",
      label: "Prix",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>{value} {Config.courrancy} </>
          );
        }
      }
    },
    {
      name: "id",
      label: "Prix",
      options: {
        filter: true,
        customBodyRenderLite: (dataIndex) => {
          return <>
          <div className="fex">
          <IconButton  onClick={(e)=>gh(data[dataIndex])} aria-label="Example">
              <EditIcon color="success"/>
          </IconButton>
          {
             data[dataIndex]['statut'] != 2 && data[dataIndex]['statut'] != 0 ?<IconButton onClick={(e)=>window.open(Config.url+`/booking/viewFacture.php?id=${data[dataIndex]['id']}`, '_blank')}>
               <ArrowCircleDownIcon/>
             </IconButton>:<></>
          }

          </div>
          </>;
      }
      }
    },
  ];

  // function edit 

  const gh = async (data) =>{
    navigate(`/Finaletap/${data.poit_start.split(',')[0]}/${data.poit_start.split(',')[1]}/${data.poit_end.split(',')[0]}/${data.poit_end.split(',')[1]}/${data.location_start}/${data.location_end}/${data.distance}/${data.time}/${data.id_category}/${data.date_starting}/${1}/${data.id}`);
  }

  const [type,setType] = useState(2);
  const GetData = async () =>{
    const Res = await CmdSer.getHistorique(user.id,"-99",10000000000,0);
    let datafilter = [];
    Res.data.forEach(element => {
      if(type==1){
        if(element.statut!="10"){
          if(element.statut!="-15")datafilter.push(element);
        }
      }else{
        if(element.statut=="10"){
          if(element.statut!="-15")datafilter.push(element);
        }
      }

    });
    setdata(datafilter);
  }

useEffect(() => {
  if (!islogin) navigate("/Login");
GetData();

}, [type]);

  return (
    <Styled>
   <Container >
   <div className="hero">
      <h3>Vos Trajets</h3>
      <p>Afficher le detail de vos réservations</p>
        </div>
        <div className='cartfillter'>
        <Grid container spacing={2}>
        <Grid item xs={6}>
       <FormControl fullWidth >
        <InputLabel id="demo-simple-select-autowidth-label"> Type de réservation</InputLabel>
        <Select
          value={type}
          onChange={(e)=>setType(e.target.value)}
          autoWidth
          label="Type de réservation">
          <MenuItem value={2}>Courses à venir</MenuItem>
          <MenuItem value={1}>Courses terminéés</MenuItem>
        </Select>
      </FormControl>
      </Grid>
      <Grid item xs={6}>

      </Grid>
      </Grid>
        </div>
        <MUIDataTable
          data={data}
          columns={columns}
        />
   </Container>
    </Styled>
  )
}
