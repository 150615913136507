import axios from "axios";
import ApiConfig from "./Config.js";
const  http2 = axios.create({
  headers: {
    "Content-type":  ApiConfig.content_type
  }
})
;

 const  http = axios.create({
  baseURL: ApiConfig.url,
  headers: {
    "Content-type":  ApiConfig.content_type
  }
})
;

export default http;
