import Booking from "./views/Booking";
import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
//import { Finaletap } from "./views/Finaletap";
import Main from "./context/Main";
import ViewPage from "./views/ViewPage";
import Login from "./views/Login";
import SignUp from "./views/SignUp";
import { Finaletap } from "./views/Finaletap";
import { MyAccount } from "./views/MyAccount";
import { Favorite } from "./views/Favorite";
import { BookingList } from "./views/BookingList";
import Map2 from "./views/Map2";
import { CardList } from "./views/CardList";
import Checkout from "./views/Checkout";

function App() {
  const libraries = ["geometry","drawing","places"]
  const {isLoaded, loadError} = useJsApiLoader({
    googleMapsApiKey:"AIzaSyC6x3KJb3vxdGQwUMRWkgvCq4rEDNFtMsU",
    libraries,
  })
  return isLoaded ? 
  <Main>
  <BrowserRouter>
  <Routes>
   <Route path="/" element={<ViewPage><Booking /></ViewPage> }/>
   <Route path="/login" element={<ViewPage><Login /></ViewPage> }/>
   <Route path="/Checkout" element={<ViewPage><Checkout /></ViewPage> }/>
   <Route path="/SignUp" element={<ViewPage><SignUp /></ViewPage> }/>
   <Route path="/MyAccount" element={<ViewPage><MyAccount /></ViewPage> }/>
   <Route path="/CardList" element={<ViewPage><CardList /></ViewPage> }/>
   <Route path="/BookingList" element={<ViewPage><BookingList /></ViewPage> }/>
   <Route path="/Favorite" element={<ViewPage><Favorite /></ViewPage> }/>
   <Route path="/Finaletap/:start_lat/:start_lng/:end_lat/:end_lng/:adrrstart/:adrrend/:km/:time/:type/:date_start/:typeBooking" element={<ViewPage><Finaletap /></ViewPage>}/>
   <Route path="/Finaletap/:start_lat/:start_lng/:end_lat/:end_lng/:adrrstart/:adrrend/:km/:time/:type/:date_start/:typeBooking/:id" element={<ViewPage><Finaletap /></ViewPage>}/>
  
  </Routes>
  </BrowserRouter>
  </Main>
  : <></>
}
export default React.memo(App)
