const Config = {
    url:'https://admin.wegobox.fr/api',
    apikey:'968C6B9AB627E7289EA8736D3961B',
    content_type:'Content-type',
    img_url:'https://admin.wegobox.fr/',
    id_partnair:"1",
    Copyright_name:"Wegobox",
    Copyright_url:"https://wegobox.fr/",
    firebase:false,
    courrancy:"€",
    pages:true,
    loginWithPhon:true,
    bilalid:58049
};
export default Config;