import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from '../src/GlobalStyle.js';
import "react-datepicker/dist/react-datepicker.css";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2dd855',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#212121',
    },
  },
});
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)
ReactDOM.render(
<ThemeProvider theme={theme}>
<LocalizationProvider dateAdapter={AdapterDateFns}>
  <React.StrictMode>
  <GlobalStyle/>
  <App />
  <ToastContainer />
</React.StrictMode>
</LocalizationProvider>
</ThemeProvider>



  ,
  document.getElementById('root')
);


