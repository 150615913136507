import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const Register = () => {
  return (
    <>
      <Form className="form_login">
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3" >
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" placeholder="Enter First Name" />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3" >
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Last Name" />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" >
          <Form.Label>Email address</Form.Label>
          <Form.Control type="email" placeholder="Enter email" />
        </Form.Group>
        <Form.Group className="mb-3" >
          <Form.Label>Phone</Form.Label>
          <Form.Control type="text" placeholder="Enter Phone" />
        </Form.Group>

        <Form.Group className="mb-3" >
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" />
        </Form.Group>

        <Form.Group className="mb-3" >
          <Form.Label>Retry Password</Form.Label>
          <Form.Control type="password" placeholder="Password" />
        </Form.Group>

        <Button variant="primary" onClick={(e) => console.log("dfs")}>
          Register
        </Button>
      </Form>
    </>
  );
};

export default Register;
