import React, { useContext, useState } from "react";
import { Offcanvas, Tabs, Tab } from "react-bootstrap";
import Copyright from "../com/Copyright.js";
import Historique from "../com/Historique.js";
import { Login } from "../com/Login";
import MyAccount from "../com/MyAccount";
import Register from "../com/Register";
import { CartStateContext } from "../context/Main.jsx";
import Header from "./Header";
import logo from "../image/logo.png";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonIcon from "@mui/icons-material/Person";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import PaymentIcon from "@mui/icons-material/Payment";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import FavoriteIcon from '@mui/icons-material/Favorite';
import KeyIcon from '@mui/icons-material/Key';
const ViewPage = (props) => {
  const { islogin } = useContext(CartStateContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [drawer, setdrawer] = useState(false);
  const [info, setinfo] = useState(false);
  const handleCloseinfo = () => setinfo(false);
  const handleShowinfo = () => setinfo(true);

  const handledrawer = () => setdrawer(true);
  const handleClosedrawer = () => setdrawer(false);
  return (
    <>
      <Header
        handleShow={handleShow}
        handleShowinfo={handleShowinfo}
        handledrawer={handledrawer}
      />
      {props.children}

      {<Copyright />}

      <Offcanvas
        show={drawer}
        onHide={handleClosedrawer}
        id="eddfdf"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img className="logoDrawer" src={logo} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <List>
            <ListItem component={Link} onClick={handleClosedrawer} to="/MyAccount" button>
              <ListItemIcon>
                <PersonIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </ListItem>

            <ListItem component={Link} onClick={handleClosedrawer} to="/CardList" button>
              <ListItemIcon>
                <PaymentIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText>Paiement</ListItemText>
            </ListItem>

            <ListItem component={Link} to="/Favorite"  onClick={handleClosedrawer} button>
              <ListItemIcon>
                <FavoriteIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText>Adresses favorites</ListItemText>
            </ListItem>

            <ListItem component={Link} onClick={handleClosedrawer} to="/BookingList" button>
              <ListItemIcon>
                <FeaturedPlayListIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText>Trajets</ListItemText>
            </ListItem>
          </List>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tabs
            defaultActiveKey="login"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="login" title="login">
              <Login handleClose={handleClose} />
            </Tab>
            <Tab eventKey="register" title="register">
              <Register />
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={info} onHide={handleCloseinfo} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tabs
            defaultActiveKey="myaccount"
            transition={false}
            id="noanim-tab-example"
            className="mb-3 info"
          >
            <Tab eventKey="myaccount" title="My account">
              <MyAccount />
            </Tab>
            <Tab eventKey="historique" title="Historique">
              <Historique />
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ViewPage;
