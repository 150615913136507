import http from "../http-common";
import http2 from "../http-common2";
import Config from "../Config";

const  getPrice =  (datasande) => {
  const data = {
    "km":datasande.km,
    "time":datasande.time,
    "start_lat":datasande.start_lat,
    "start_lng":datasande.start_lng,
    "end_lat":datasande.end_lat,
    "end_lng":datasande.end_lng,
    "apikey":Config.apikey
  };
  return http.post("/price.php",data);
};



const creatOrder = (data) => {
  return http.post("/orders/creat_orders.php",data);
}


const creatComande = (data) => {
  return http.post("/booking/write.php",data);
}


const login = (datad) =>{
  const data = {
    "email":datad.email.replaceAll(' ',''),
    "pass":datad.pass,
    "apikey":Config.apikey
  };
  return http.post("/client/login.php",data);
}
const loginWithPhone = (datad) =>{
  const data = {
    "phone_number":datad.phone_number.replaceAll(' ',''),
    "pass":datad.pass,
    "apikey":Config.apikey
  };
  return http.post("/client/login.php",data);
}


const rigister = (datad) =>{
   datad.apikey = Config.apikey
  return http.post("/client/write.php",datad);
}


const userdata = (id) => {
  const data =  {
    "id_rider":id,
    "apikey":Config.apikey

  }
  return http.post("/client/read.php",data);
}


const updateDataUser = (datad) => {
  datad.apikey = Config.apikey;
  return http.post("/client/update.php",datad);
}


const getHistorique = (id,state,limit,offset) =>{
  const data =  {
    "id_rider":id,
    "state":state,
    "limit":limit,
    "offset":offset,
    "apikey":Config.apikey
  }
  return http.post("/booking/readbyclient.php",data);
}



const verification = (phone_number) => {
  const data =  {
    "phone_number":phone_number.replaceAll(' ',''),
    "apikey":Config.apikey
  }
  return http.post("/client/verification.php",data);
}



const getIdfromTlf = (phone_number) => {
  const data =  {
    "phone_number":phone_number.replaceAll(' ',''),
    "apikey":Config.apikey
  }
  return http.post("/client/restpass.php",data);
}

const getMycode = () => {
  return http2.get("https://iplocate.io/api/lookup");
}

// address favorite_addresses 
const add_favorite_addresses = (datad) => {
  datad.apikey = Config.apikey;
  return http.post("/client/add_favorite_addresses.php",datad);
}

const update_favorite_addresses = (datad) => {
  datad.apikey = Config.apikey;
  return http.post("/client/update_favorite_addresses.php",datad);
}

const get_favorite_addresses = (datad) => {
  datad.apikey = Config.apikey;
  return http.post("/client/get_favorite_addresses.php",datad);
}




// address favorite_card 
const add_favorite_card = (datad) => {
  datad.apikey = Config.apikey;
  return http.post("/client/add_favorite_card.php",datad);
}

const update_favorite_card = (datad) => {
  datad.apikey = Config.apikey;
  return http.post("/client/update_favorite_card.php",datad);
}

const get_favorite_card = (datad) => {
  datad.apikey = Config.apikey;
  return http.post("/client/get_favorite_card.php",datad);
}

const getMatrixApi = (datad) => {
  datad.apikey = Config.apikey;
  return http.post("/googleMatrix.php",datad);
}


const changestate = (data) => {
  data.apikey = Config.apikey;
  return http.post("/booking/setstate.php",data);
} 


// get devis 

const get_devise_zone = (data) => {
  data.apikey = Config.apikey;
  return http.post("/get_devise_zone.php",data);
} 




const CmdSer = {
 getPrice,
 creatOrder,
 creatComande,
 verification,
 login,
 rigister,
 userdata,
 updateDataUser,
 getIdfromTlf,
 loginWithPhone,
 getMycode,
 add_favorite_addresses,
 update_favorite_addresses,
 get_favorite_addresses,
 add_favorite_card,
 update_favorite_card,
 get_favorite_card,
 getHistorique,
 changestate,
 getMatrixApi,
 get_devise_zone
};


export default CmdSer;