import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Container from "@mui/material/Container";
import MUIDataTable from "mui-datatables";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Box, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import "react-credit-cards/es/styles-compiled.css";
import Cards from "react-credit-cards";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Moment from "moment";
import Button from "@mui/material/Button";
import { CartStateContext } from "../context/Main";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import CmdSer from "../Service/CmdSer";
import creditCardType from 'credit-card-type';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
Moment.locale("en");
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 90 + "%",

  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const Styled = styled.div`

  .ghModal {
    max-width: 700px;
  }

  .df {
    position: fixed;
    bottom: 5%;
    right: 5%;
  }
  margin-top: 100px;
  .hero {
    background-color: #212121;
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 5px;
  }
`;

export const Favorite = () => {
    const columns = [
        {
            name: "address",
            label: "Address",
            options: {
              filter: true
            },
          },
          {
            name: "id",
            label: "Action",
            options: {
              filter: true,
              customBodyRenderLite: (dataIndex) => {
                return <>
                <div className="fex">
                <Switch
                checked={data[dataIndex].state=="1"?true:false}
                onChange={(e)=>updateData({id:data[dataIndex].id,state: data[dataIndex].state==1?'0':'1'})}
                inputProps={{ 'aria-label': 'controlled' }}
                />
                <IconButton  onClick={(e)=>setcard({...card,
                    id:data[dataIndex].id,
                    lat: data[dataIndex].lat,
                    lng: data[dataIndex].lng,
                    address: data[dataIndex].address,
                    state: data[dataIndex].state,
                    })&setupdate(true)&setOpen(true)&console.log(card)} aria-label="Example">
                    <EditIcon color="success"/>
                </IconButton>

                </div>
                </>;
            }
            },
          }

      ];
      const options = {
        filterType: "checkbox",
    };
  const { islogin,user } = useContext(CartStateContext);
  const [data,setdata] =  React.useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [loading,setloading] = useState(false);
  const inputRef = useRef(null);
  const handleOpen = () => setcard({
    id:"",
    lat: "",
    lng: "",
    address: "",
    state: 1,
  })&setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState();
  const [card, setcard] = useState({
    id:"",
    lat: "",
    lng: "",
    address: "",
    state: 1,
  });

  const [update, setupdate] = useState(false);

  const updateData = (data) => {
    setupdate(false);
    setloading(true);
    setTimeout(async () => {
    const res = await CmdSer.update_favorite_addresses(data);
    setloading(false);
    toast.success('Mise à jour réussie', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    },1000);

  }

  const addData = () => {
    setloading(true);
    setTimeout(async () => {
        if(card.cvc!=""&&card.expiry!=""&&card.name!=""&&card.number!=""){
            const res = await CmdSer.add_favorite_addresses({
                id_client:user.id,
                address:card.address,
                lat:card.lat,
                lng:card.lng
            });

            if(res.data.state=="ok"){
                setOpen(false);
                toast.success('Ajouté avec succès', {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
                
            }
        }else{
            toast.error('Veuillez entrer toutes les données', {
                position: toast.POSITION.BOTTOM_LEFT,
              });
        }
    setloading(false);
    },1000);


  }

const getDate = async () => {
    const re = await CmdSer.get_favorite_addresses({id_client:user.id});
    setdata(re.data);

}

const data_map = async (data) => {
  const df = await geocodeByPlaceId(data.value.place_id);
  const tLatLng = await getLatLng(df[0]);
  setcard({...card,lat:tLatLng.lat,lng:tLatLng.lng,address:data.label});
}


useEffect(() => {
    if (!islogin) navigate("/Login");
     getDate();
}, [loading]);

  return (
    <Styled>
      <Container>
        <div className="hero">
          <h3>Vos Adresses favorites</h3>
          <p>Visualisez, modifiez et ajoutez de nouvelles Adresses favorites</p>
        </div>

        <MUIDataTable data={data} columns={columns} options={options} />
        <Fab
          onClick={handleOpen}
          color="primary"
          className="df"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          id="sdsds111"
          aria-describedby="modal-modal-description"
        >
          <Box className="ghModal" sx={style}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                  <GooglePlacesAutocomplete
    apiOptions={{ language: 'fr', region: 'fr' }}
    selectProps={{ 
        placeholder: update?card.address:"Address",
        onChange: (data) => data_map(data), value: value 
    }}
/>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton 
                loading={loading}
                onClick={(e)=>update?updateData({
                    id_client:user.id,
                    id:card.id,
                    address:card.address,
                    lat:card.lat,
                    lng:card.lng
                }):addData()} 
                variant="contained">
                    {update?"Mettre à jour":"Ajouter"}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Container>
    </Styled>
  );
};
